define({
  lp_dc: 'lo',
  lp_zone: 'z2',

  //domains

  __createAccountUrl: {
    qa: 'accountcreation-app.qa.int.gw.lpcloud.io',
    default: '{{{dc}}}.acc-create.liveperson.net'
  },
  __AdsUrl: {
    qa: 'qtvr-wap01.dev.lprnd.net',
    default: '{{{zone}}}.ads.liveperson.net'
  },
  __loggos: {
    qa: 'loggos-app.qa.int.gw.lpcloud.io',
    default: '{{{zone}}}.loggos.liveperson.net'
  },
  __adminArea: {
    qa: 'lp-prun-web.qa.int.gw.lpcloud.io',
    'va-a': 'base.liveperson.net',
    'z0-intg': 'server.fr.liveperson.net',
    z1: 'server.iad.liveperson.net',
    z2: 'server.lon.liveperson.net',
    z3: 'server.sy.liveperson.net'
  },
  __authDomain: {
    qa: 'https://authentication.qa.int.gw.lpcloud.io',
    alpha: 'https://va-a.authentication.liveperson.net',
    ga: 'https://authentication.liveperson.net'
  },
  __intentDashboardDomain: {
    qa: 'https://intent-qa.dev.lprnd.net',
    default: 'https://{{{zone}}}.intent.liveperson.net'
  },
  __performanceOptimizerDomain: {
    qa: 'https://performance-portal-qa.dev.lprnd.net',
    default: 'https://{{{dc}}}.performance.liveperson.net',
  },
  __staffForecasterDomain: {
    qa: 'https://lp-staffforecaster-qa.dev.lprnd.net',
    default: 'https://{{{dc}}}.staff-forecaster.liveperson.net',
  },
  __dataTransporterDomain: {
    qa: 'https://lp-dataconnector-web-qa.dev.lprnd.net',
    default: 'https://{{{dc}}}.transporter.liveperson.net',
  },
  __connectionPanel: {
    qa: 'https://connection-panel-app.qa.int.gw.lpcloud.io',
    'va-a': 'https://z1-a.connection-panel.liveperson.net',
    z1: 'https://z1.connection-panel.liveperson.net',
    z2: 'https://z2.connection-panel.liveperson.net',
    z3: 'https://z3.connection-panel.liveperson.net'
  },

  __visitorFeedV2: {
    qa: 'https://visitorfeedv2-qa.dev.lprnd.net',
    'va-a': 'https://z1-a.visitorfeedv2.liveperson.net',
    'z1-a': 'https://z1-a.visitorfeedv2.liveperson.net',
    z1: 'https://z1.visitorfeedv2.liveperson.net',
    z2: 'https://z2.visitorfeedv2.liveperson.net',
    z3: 'https://z3.visitorfeedv2.liveperson.net'
  },

  __messagingGatewayDomain: {
    qa: 'lp-connector2sms-qa.dev.lprnd.net',
    ca: 'ca1.msg-gw.liveperson.net',
    default: '{{{dc}}}.msg-gw.liveperson.net'
  },

  __botCentralDomain: {
    dev: 'https://botplatform-dev.dev.lprnd.net',
    tlv: 'https://botplatform-dev.dev.lprnd.net',
    'va-a': 'https://va-a.botplatform.liveperson.net',
    z1: 'https://va.botplatform.liveperson.net',
    z2: 'https://lo.botplatform.liveperson.net',
    z3: 'https://sy.botplatform.liveperson.net'
  },
  __mavenDomain: {
    qa: 'https://lp-mavenhub-qa.dev.lprnd.net',
    'va-a': 'https://va-a.maven.liveperson.net',
    default: 'https://{{{zone}}}.maven.liveperson.net'
  },
  __LPTagDomainsOverrideConfig: {
    z0: "{domain: 'lp-lptag-app.qa.int.gw.lpcloud.io', tagjs: 'lp-lptag-app.qa.int.gw.lpcloud.io'}",
    'z0-intg':
      "{domain: 'lptag-z0-intg.liveperson.net', tagjs: 'lptag-z0-intg.liveperson.net'}",
    'z1-a':
      "{domain: 'lptag-a.liveperson.net', tagjs: 'tags-a.liveperson.net'}",
    default: '{}'
  },
  Session: {
    LeServer: 43200,
    KeepAliveInterval: 180,
    AppServerKeepAliveInterval: 1500,
    WarnBeforePeriod: 120,
    BlacklistCodes: [401, 405],
    FaultlistCodes: [404, 500, 502, 503, 504],
    MaxFaultTolerance: 4
  },
  __Session: {
    tlv: { LeServer: 1500 }
  },
  __whatsappBusinessTermsDocument: {
    z1:
      'https://liveperson.na1.echosign.com/public/esignWidget?wid=CBFCIBAA3AAABLblqZhDFFuEDr7w_DvDU0ApHFo4nH-Q7ica7jQxMzf8JK7E3-za8N-lJSnkY3Bzfbt3UD88*',
    default:
      'https://liveperson.na1.echosign.com/public/esignWidget?wid=CBFCIBAA3AAABLblqZhAecQgYrv30BGOKMRO_6AJvef40lX845xNEWX1hFxXO-hltn6RXPTkCmn1MdOZGJNw* '
  },

  /* Support account on Alpha */
  /*
    "LPtag_mtaconfig": {
        "domain": "lptag-a.liveperson.net",
        "tagjs": "tags-a.liveperson.net",
        "siteId": "75555851"
    },
    */
  /* End Support account on Alpha */

  /* Support account on VA */
  LPtag_mtaconfig: {
    domain: 'lptag.liveperson.net',
    tagjs: 'tags.liveperson.net',
    siteId: '5288836'
  },
  /* End Support account on VA */

  __LPtag_mtaconfig: {
    tlv: {
      domain: 'lp-lptag-app.qa.int.gw.lpcloud.io',
      tagjs: 'lp-lptag-app.qa.int.gw.lpcloud.io',
      siteId: 'le66223394'
    },
    dev: {
      domain: 'lp-lptag-app.qa.int.gw.lpcloud.io',
      tagjs: 'lp-lptag-app.qa.int.gw.lpcloud.io',
      siteId: 'le66223394'
    }
  },

  __LPtag_config_url: {
    qa:
      'https://lpcdn.lpsnmedia.net/p/lp-cp-tag-support/latest/support_config.json',
    default:
      'https://lpcdn.lpsnmedia.net/p/lp-cp-tag-support/latest/support_config_prod.json'
  },

  __ssoConfigurationKey: {
    tlv: 'A',
    'z1-a': 'LEAlpha',
    default: 'LEGA{{{zone}}}'
  },

  __useFallBackDicitionary: {
    tlv: true,
    default: true
  },

  Billing: {
    trialTimeout: 30,
    oneSeatEnabled: true,
    freemiumDaysBarrier: 300,
    LPComRegisterUrl:
      'https://register.liveperson.com/upgrade?upgradeSiteId={accountId}',
    LPComMyAccountUrl:
      'https://register.liveperson.com/user/login/myaccount?upgradeSiteId={accountId}'
  },
  __Billing: {
    tlv: {
      trialTimeout: 5
    },
    'z1-a': {
      LPComRegisterUrl:
        'https://staging-register.liveperson.com/upgrade?upgradeSiteId={accountId}',
      LPComMyAccountUrl:
        'https://staging-register.liveperson.com/user/login/myaccount'
    }
  },

  __scraperTagletId: {
    tlv: 78597510,
    'z1-a': 10182510,
    default: 24411912
  },

  __environment: {
    tlv: 'qa',
    'z1-a': 'alpha',
    default: 'ga'
  },

  __LPApiAppKey: {
    tlv: '6120eac943a641988c6d8d712bd00579',
    default: '6b7bb399d22047ee9ca34d915714d995'
  },

  connectionPanelChatSettings: {
    fallbackMonitoringDomain: 'base.liveperson.net',
    fallbackSupportAccount: {
      siteId: '801519',
      appKey: '6b7bb399d22047ee9ca34d915714d995'
    },
    fallbackSupportContact: {
      description: 'Need help? Talk to us!',
      displayName: 'Customer Support',
      img:
        'https://s3.amazonaws.com/LPGA/cms::med::ec0b5c65-19cc-4923-d209-09221efccf23',
      skillId: '3',
      id: 3,
      skillName: 'LiveEngage2',
      backupSkillName: null,
      backupSkillId: null,
      engagementType: 'messaging',
      contactType: 'group'
    },
    fallbackSalesContact: {
      description: 'Questions about pricing?',
      displayName: 'Online Sales',
      skillName: 'Registration',
      skillId: '6',
      id: 6,
      img:
        'https://s3.amazonaws.com/LPGA/cms::med::163fd063-6d9d-49cb-b6d8-6958483c737a',
      backupSkillName: null,
      backupSkillId: null,
      engagementType: 'messaging',
      contactType: 'group'
    }
  },

  __connectionPanelChatSettings: {
    tlv: {
      fallbackMonitoringDomain: 'hc1.dev.lprnd.net',
      fallbackSupportAccount: {
        siteId: 'le77576385',
        appKey: 'c60fd5e4b3f44da4920c6fd8acb49558'
      },
      fallbackSupportContact: {
        skillId: '269594312',
        id: 269594312
      },
      fallbackSalesContact: {
        skillId: '343728812',
        id: 343728812
      }
    }
  },

  /* CP Settings for support account on Alpha */
  /*
    "connectionPanelSettings": {
        "chat": {
            "visitorSessionRetryInterval": 1000,
            "visitorSessionMaxAttempts": 5,
            "soundFilesLocation": "/src/modules/connection/assets/sounds/",
            "waitForSurveyTimeout": 60000
        },
       "unifiedWindow": {
           "environment": "prod",
           "windowId": "88928613",
           "authenticationType": "OAUTH_IMPLICIT"
       }

    },
    */
  /* CP Settings for support account on Alpha */

  /* CP Settings for support account on VA */
  connectionPanelSettings: {
    chat: {
      visitorSessionRetryInterval: 1000,
      visitorSessionMaxAttempts: 5,
      soundFilesLocation: '/src/modules/connection/assets/sounds/',
      waitForSurveyTimeout: 60000
    },
    unifiedWindow: {
      environment: 'prod',
      windowId: '1246528514',
      authenticationType: 'OAUTH_IMPLICIT'
    }
  },
  /* CP Settings for support account on VA */

  __connectionPanelSettings: {
    tlv: {
      unifiedWindow: {
        environment: 'qa',
        windowId: '302813612',
        authenticationType: 'OAUTH_IMPLICIT'
      }
    },
    dev: {
      unifiedWindow: {
        environment: 'qa',
        windowId: '302813612',
        authenticationType: 'OAUTH_IMPLICIT'
      }
    }
  },

  __facebookConfiguration: {
    tlv: { showFacebookConnector: true, appId: '2741069036108410' },
    'z1-a': { showFacebookConnector: true, appId: '124455461233435' },
    z1: { showFacebookConnector: true, appId: '1012962928756865' },
    z2: { showFacebookConnector: true, appId: '1712570165669094' },
    z3: { showFacebookConnector: true, appId: '690022217842607' },
    default: { showFacebookConnector: true, appId: '2741069036108410' }
  },

  __instagramConfiguration: {
    tlv: { showFacebookConnector: true, appId: '846735059430234' },
    'z1-a': { showFacebookConnector: true, appId: '745317913060946' },
    z1: { showFacebookConnector: true, appId: '392051075515930' },
    z2: { showFacebookConnector: true, appId: '470330110604696' },
    z3: { showFacebookConnector: true, appId: '668758877152698' },
    default: { showFacebookConnector: true, appId: '846735059430234' }
  },

  __emailConnectorConfigurationDomain: {
    qa: 'https://lp-maamonboarding-qa.dev.lprnd.net',
    lo: false,
    nl: false,
    sy: false,
    ml: false,
    default: 'https://{{{dc}}}.maam-setup.liveperson.net'
  },

  __socialConnectConnectorConfigurationDomain: {
    qa: 'https://socialconnect.dev.conversable.com/entry/{{{zone}}}',
    'z1-a': 'https://socialconnect.staging.conversable.com/entry/{{{zone}}}',
    z1: 'https://oh.socialconnect.liveperson.net/entry/{{{zone}}}',
    z2: 'https://sy2.socialconnect.liveperson.net/entry/{{{zone}}}',
    z3: 'https://du.socialconnect.liveperson.net/entry/{{{zone}}}',
    default: 'https://oh.socialconnect.liveperson.net/entry/{{{zone}}}'
  },

  __backeryDomains: {
    z0: 'https://le-ui-web.qa.int.gw.lpcloud.io',
    'z1-a': 'https://z1-a.le.liveperson.net',
    z1: 'https://z1.le.liveperson.net',
    z2: 'https://z2.le.liveperson.net',
    z3: 'https://z3.le.liveperson.net',
  },

  //static values (hopefully)
  LPService: 'liveEngage',
  defaultRoute: '/wa/webagent',
  connectorsOnboardingSurveyDomain: 'https://www.liveperson.com',
  serviceStatusDomain: 'https://status.liveperson.com',
  developersCommunityDomain: 'https://developers.liveperson.com',
  livepersonIncPath: 'https://livepersoninc.github.io/dev-hub',
  connectionPanelEnabled: true,
  connectionPanelAutoStart: true,
  connectionPanelAvailabilityCheckInterval: 30000,
  NotificationsDefaults: {
    DesktopNotification: {
      focusStatus: 1
    }
  },
  customWidgets: {
    loadTimeout: 30000,
    maxParallelTasks: 2
  },
  LPPublicKey:
    'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQC0RYy+YqdUE2rR0gWhT3B8+JjAhGvwSFepUOiFH6QZZXmlCsqu63BztnWSPF3Cjz68K4WAiY5P18AVkGa2JWqVv6v/XaugJHwCRE3TBoG7ddFkecTYEVjWalBRCtvP19deGjBzSHXbt1iy2Y79KP4y43UQXgjQyNCejahpQm/7rwIDAQAB',

  // feature flags
  c4mSkillSelection: true,
  supportDeletedUsers: true,
  c4mMobileApp: true,
  appCreation: true,
  usersBatchUpdates: {
    enabled: true
  },
  actionableConversations: true,
  scheduledWorkingHours: true,
  scheduledCampaigns: true,
  sendPDCEventForMessaging: true,
  transferSkills: true,

  useNewVisitorFeedV2: true,
  visitorfeedv2FixAccounts: [],
  postConvSurveyFilter: true,
  postConvSurveyFilterInvalidFreeText: true,
  postConvSurveyFilterOpenText: true,
  automationTabLandingVideo: 'https://player.vimeo.com/video/302277356',
  appsConfig: {
    dev: {
      basePathTemplate: '{lpCdnDomain}:{version}/',
      filePath: 'app.js'
    },
    app: {
      basePathTemplate: '{lpCdnDomain}le/apps/{appName}/{version}/',
      filePath: 'js/app.js'
    }
  },

  leDependenciesConfig: {
    infra: {
      basePathTemplate: '{lpCdnDomain}le/infra/vue/{version}/',
      filePath: 'js/app.infra.global.min.js'
    }
  },
  defaultLeResourceDomain: 'https://lp-cdn-qa.dev.lprnd.net/',

  topLevelExternalLinks: {
    knowledgeCenter: 'https://knowledge.liveperson.com',
    developersCommunity: 'https://developers.liveperson.com'
  },
  enableExternalKnowledgeCenterKpLinks: true,
  externalKnowledgeCenterLinkTemplate:
    'https://knowledge.liveperson.com/{{kpID}}.html',
  __launchpadClientId: {
    dev: 'ac47580c-b1db-4b72-86bf-c6aed92c867d',
    tlv: 'ac47580c-b1db-4b72-86bf-c6aed92c867d',
    'z1-a': '86fb9018-803f-430f-a2d7-f9e2e55bf3be',
    default: 'd59df109-c246-4715-b5eb-e43eaacd0759'
  },
  externalDevelopersCommunityLinkTemplate:
    'https://developers.liveperson.com/{{kpID}}.html',
  wsGetClockInterval: 10000,
  agentSurveyFilter: true,
  showNewAgentWorkspaceInvitation: false,
  showMessagingLoadBalance: true,
  addSourceQueryParameter: true,
  showIVRservice: true,
  isBamSupportEnabled: true,
  sentry: {
    isEnabled: false,
    dsn : 'https://97ef04c511bd0da13f3a272e1712da83@o4505787112554496.ingest.sentry.io/4506172863021056',
    sampleRate: 0,
    tracesSampleRate: 0,
    profilesSampleRate: 0,
    tracePropagationTargets: [],
    blockedAccounts: [],
    enableMemoryTrackingMetrics: false,
    enableMemoryTrackingAccountsList: [],
    userSamplingProperties: {}
  },
  __blockInternetExplorer: true,
  __isNewNavbar: true,
  __isAgentStateIsDecoupleFromDenver: false,
  __isPendoAnnoucmentsEnabled: false,
  __isHidingChat:false,
  __hasCoreWidgetEditAndProfileFilter: false,
  __navbarConfiguration: {
    categories: {
      AgentWorkspace: {
        icon: "agent-workspace",
        languageKey: "navbar.appDisplayName.agentWorkspace",
        apps: [
          {
            subCategory: "PinnedAgentWorkspace",
            icon: "agent-workspace",
            appDisplayName: "Agent Workspace",
            languageKey: "navbar.appDisplayName.agentWorkspace",
            moduleName: "LEWebAgentVeu",
            language:{
              appDisplayName:"navbar.appDisplayName.agentWorkspace",
            }
          },
        ]
      },
      ManagerWorkspace: {
        icon: "manager-workspace",
        languageKey: "navbar.appDisplayName.managerWorkspace",
        apps: [
          {
            subCategory: "PinnedManagerWorkspace",
            icon: "manager-workspace",
            appDisplayName: "Manager Workspace",
            languageKey: "navbar.appDisplayName.managerWorkspace",
            moduleName: "LEAgentManagerDashboardVue",
            language:{
              appDisplayName:"navbar.appDisplayName.managerWorkspace",
            }
          },
        ]
      },
      Engage: {
        icon: "message-chat-square",
        languageKey: "navbar.category.engage",
        apps: [
          {
            subCategory: "Converse",
            appDisplayName: "Agent Workspace For Chat",
            moduleName: "LEWebAgent",
            language:{
              subCategory:"navbar.subCategory.converse",
              appDisplayName:"navbar.appDisplayName.agentWorkspaceForChat",
            }
          },
          {
            subCategory: "Converse",
            appDisplayName: "RealTime Dashboard",
            moduleName: "real-time-dashboard",
            quickLaunchAppIDs: {
              qa: "11134564-1492-4c7f-be4f-dc9ba0658ae0",
              alpha: "7ecbff41-873d-4f04-b52c-17d8e03b29b4",
              ga: "995814e2-87ff-42c1-a51c-d462ac9bf818"
            },
            addAccountIdQueryParam: true,
            isExternal: true,
            acFeatures: [],
            privileges: {
              AND: [],
              OR: []
            },
            language:{
              subCategory:"navbar.subCategory.converse",
              appDisplayName:"navbar.appDisplayName.realTimeDashboard",
            }
          },
          {
            subCategory: "Connect",
            appDisplayName: "Campaign Builder",
            moduleName: "LECampaigns",
            language:{
              subCategory:"navbar.subCategory.connect",
              appDisplayName:"navbar.appDisplayName.campaignBuilder",
            }
          },

          {
            subCategory: "Connect",
            appDisplayName: "Proactive Messaging",
            moduleName: "proactive-messaging",
            quickLaunchAppIDs: {
              qa: "7aaa00e2-4c49-4350-9121-d7ddda0f2a1b",
              alpha: "0b0e2cd4-d8bc-4a13-802e-eb2b6fce6290",
              ga: "26890c5e-17a0-4a91-a200-b3fa73f54f3a"
            },
            addAccountIdQueryParam: true,
            isExternal: true,
            acFeatures: [],
            privileges: {
              AND: [],
              OR: []
            },
            language:{
              subCategory:"navbar.subCategory.connect",
              appDisplayName:"navbar.appDisplayName.proactiveMessaging",
            }
          },
          {
            subCategory: "Connect",
            appDisplayName: "Connect to Messaging",
            moduleName: "connect-to-messaging",
            quickLaunchAppIDs: {
              qa: "d1f4a096-245c-478f-95b9-6fe6106194ba",
              alpha: "b73f25c0-1901-46a9-aabe-350ef8f635f7",
              ga: "0ae4d66f-521a-4def-a8f1-d0c43f243cdf"
            },
            addAccountIdQueryParam: true,
            isExternal: true,
            acFeatures: [],
            privileges: {
              AND: [],
              OR: []
            },
            language:{
              subCategory:"navbar.subCategory.connect",
              appDisplayName:"navbar.appDisplayName.connectToMessaging",
            }
          },
          {
            subCategory: "Connect",
            appDisplayName: "Secure Form Studio",
            moduleName: "lp-le-secure-form-studio",
            language:{
              subCategory:"navbar.subCategory.connect",
              appDisplayName:"navbar.appDisplayName.secureFormStudio",
            }
          }
        ]
      },
      Automate: {
        icon: "bot-automation",
        languageKey: "navbar.category.automate",
        apps: [
          {
            subCategory: "Model",
            appDisplayName: "Intent Manager",
            moduleName: "lp-le-conversational-ai",
            subModulePathName: "intentManager",
            language:{
              subCategory:"navbar.subCategory.model",
              appDisplayName:"navbar.appDisplayName.intentManager",
            }
          },
          {
            subCategory: "Build",
            appDisplayName: "Conversation Builder",
            moduleName: "lp-le-conversational-ai",
            subModulePathName: "botBuilder",
            language:{
              subCategory:"navbar.subCategory.build",
              appDisplayName:"navbar.appDisplayName.conversationBuilder",
            }
          },
          {
            subCategory: "Build",
            appDisplayName: "Policy Builder",
            moduleName: "lp-le-conversational-ai",
            subModulePathName: "policyBuilder",
            language:{
              subCategory:"navbar.subCategory.build",
              appDisplayName:"navbar.appDisplayName.policyBuilder",
            }
          },
          {
            subCategory: "Build",
            appDisplayName: "KnowledgeAI",
            moduleName: "lp-le-conversational-ai",
            subModulePathName: "knowledgeBase",
            language:{
              subCategory:"navbar.subCategory.build",
              appDisplayName:"navbar.appDisplayName.knowledgeAI",
            }
          },
          {
            subCategory: "Build",
            appDisplayName: "Conversation Assist",
            moduleName: "lp-le-conversational-ai",
            subModulePathName: "conversationAssist",
            language:{
              subCategory:"navbar.subCategory.build",
              appDisplayName:"navbar.appDisplayName.conversationAssist",
            }
          },
          {
            subCategory: "Build",
            appDisplayName: "Functions",
            moduleName: "functions",
            quickLaunchAppIDs: {
              qa: "5b8015da-dca3-43ed-b0e6-6600ca425767",
              alpha: "c21fa8c0-597e-4d94-88c4-f33a78e49264",
              ga: "5b03f50a-cba9-4e21-94a0-60f1a45fca4d"
            },
            addAccountIdQueryParam: true,
            isExternal: true,
            acFeatures: ['FaaS.FaaS'],
            privileges: {
              AND: [],
              OR: [1773, 1774, 1762]
            },
            language:{
              subCategory:"navbar.subCategory.build",
              appDisplayName:"navbar.appDisplayName.functions",
            }
          },
          {
            subCategory: "Build",
            appDisplayName: "Third Party Bots",
            moduleName: "lp-le-conversational-ai",
            subModulePathName: "botPlatform",
            language:{
              subCategory:"navbar.subCategory.build",
              appDisplayName:"navbar.appDisplayName.thirdPartyBots",
            }
          },
          {
            subCategory: "Orchestrate",
            appDisplayName: "Conversation Orchestrator",
            moduleName: "conversation-orchestrator",
            quickLaunchAppIDs: {
              qa: "d8d1c9d6-e85a-425e-8c6a-d90b20f4fa77",
              alpha: "aa65bf00-b2b3-4c48-9ecb-d88cc13a9930",
              ga: "67c6477a-e474-4be4-90ac-0273ea9ef945"
            },
            addAccountIdQueryParam: true,
            isExternal: true,
            acFeatures: [],
            privileges: {
              AND: [],
              OR: []
            },
            language: {
              subCategory: 'navbar.subCategory.orchestrate',
              appDisplayName: 'navbar.appDisplayName.conversationOrchestrator'
            }
          }
        ]
      },
      Optimize: {
        icon: "optimize-chart",
        languageKey: "navbar.category.optimize",
        apps: [
          {
            subCategory: "Report",
            appDisplayName: "Performance Optimizer",
            moduleName: "performance-optimizer",
            quickLaunchAppIDs: {
              qa: "6070bd15-e348-40bb-8ec5-75c4d073bbd9",
              alpha: "0795342d-75b8-4e92-9f05-a61f1820487f",
              ga: "cff76252-f3a5-41a5-8489-0beb2e592435"
            },
            addAccountIdQueryParam: true,
            isExternal: true,
            acFeatures: [],
            privileges: {
              AND: [],
              OR: []
            },
            language:{
              subCategory:"navbar.subCategory.report",
              appDisplayName:"navbar.appDisplayName.performanceOptimizer",
            }
          },
          {
            subCategory: "Report",
            appDisplayName: "MCS Toolkit",
            moduleName: "mcs-toolkit",
            quickLaunchAppIDs: {
              qa: "5e2eb42f-6ac7-461f-a2cd-b592c7b582a3",
              alpha: "967b598d-fdf3-4a35-9c10-b47770614e5b",
              ga: "9cb51806-b95e-4622-a12d-1695aa351606"
            },
            addAccountIdQueryParam: true,
            isExternal: true,
            acFeatures: [],
            privileges: {
              AND: [],
              OR: []
            },
            language:{
              subCategory:"navbar.subCategory.report",
              appDisplayName:"navbar.appDisplayName.MCSToolkit",
            }
          },
          {
            subCategory: "Report",
            appDisplayName: "Bot Analytics",
            moduleName: "lp-le-conversational-ai",
            subModulePathName: "botAnalytics",
            language:{
              subCategory:"navbar.subCategory.report",
              appDisplayName:"navbar.appDisplayName.botAnalytics",
            }
          },
          {
            subCategory: "Report",
            appDisplayName: "SMB Analytics",
            moduleName: "lp-le-birds-poc",
            language:{
              subCategory:"navbar.subCategory.report",
              appDisplayName:"navbar.appDisplayName.SMBAnalytics",
            }
          },
          {
            subCategory: "Report",
            appDisplayName: "Report Center",
            moduleName: "lp-le-reporting-bots",
            language:{
              subCategory:"navbar.subCategory.report",
              appDisplayName:"navbar.appDisplayName.LPReportng",
            }
          },
          {
            subCategory: "Report",
            appDisplayName: "Dar Dashboard",
            moduleName: "lp-le-usage-dashboard",
            language:{
              subCategory:"navbar.subCategory.report",
              appDisplayName:"navbar.appDisplayName.DarDashboard",
            }
          },
          {
            subCategory: "Analyze",
            appDisplayName: "Bella Insights",
            moduleName: "lp-le-bellainsights-ui",
            language:{
              subCategory:"navbar.subCategory.report",
              appDisplayName:"navbar.appDisplayName.bellaInsights",
            }
          },
          {
            subCategory: "Analyze",
            appDisplayName: "Intent Analyzer",
            moduleName: "lp-le-conversational-ai",
            subModulePathName: "intentManager",
            subModuleChildPathName: "analyze",
            language:{
              subCategory:"navbar.subCategory.analyze",
              appDisplayName:"navbar.appDisplayName.intentAnalyzer",
            }
          },
          {
            subCategory: "Analyze",
            appDisplayName: "Analytics Builder",
            moduleName: "analytics-builder",
            quickLaunchAppIDs: {
              qa: "a30d011a-7858-4500-9d3a-1d4e10729561",
              alpha: "6ff0004e-4607-4099-aedb-d3992283252b",
              ga: "17131cfd-165a-44ee-a359-cb2164e6d2e9"
            },
            isExternal: true,
            acFeatures: [],
            privileges: {
              AND: [],
              OR: []
            },
            language:{
              subCategory:"navbar.subCategory.analyze",
              appDisplayName:"navbar.appDisplayName.analyticsBuilder",
            }
          },
          {
            subCategory: "Analyze",
            appDisplayName: "VoiceBase",
            moduleName: "voice-base",
            quickLaunchAppIDs: {
              qa: "29dfd603-9e99-4aca-a346-01a6cde97936",
              alpha: "2162c54a-b328-4d5a-bd40-338c9aba17e8",
              ga: "557c7b9d-8af1-4a2c-bf8e-52eeb5701616"
            },
            addAccountIdQueryParam: false,
            isExternal: true,
            acFeatures: ["Voice.isVoiceBaseEnabled"],
            privileges: {
              AND: [],
              OR: []
            },
            language:{
              subCategory:"navbar.subCategory.analyze",
              appDisplayName:"navbar.appDisplayName.voiceBase",
            }
          },
          {
            subCategory: 'Analyze',
            appDisplayName: 'Analytics Studio Dashboards',
            moduleName: 'lp-le-analytics-studio-tableau-ui',
            acFeatures: ["Voice.isVoiceBaseEnabled"],
            privileges: {
              AND: [],
              OR: []
            },
            language: {
              subCategory: 'navbar.subCategory.analyze',
              appDisplayName: 'navbar.appDisplayName.analyticsStudioDashboards',
            },
          },
          {
            subCategory: "Analyze",
            appDisplayName: "Insights",
            moduleName: "insights",
            quickLaunchAppIDs: {
              qa: "",
              alpha: "",
              ga: ""
            },
            addAccountIdQueryParam: true,
            isExternal: true,
            acFeatures: [],
            privileges: {
              AND: [],
              OR: []
            },
            language:{
              subCategory:"navbar.subCategory.analyze",
              appDisplayName:"navbar.appDisplayName.insights",
            }
          },
          {
            subCategory: "Export",
            appDisplayName: "Data Transporter",
            moduleName: "data-transporter",
            quickLaunchAppIDs: {
              qa: "9c1f44af-fc6b-4e9a-995b-c9077b2b5c32",
              alpha: "95801333-4157-4dd2-8a53-3acf19749d7d",
              ga: "81c13ff3-db4e-4dc5-83c1-4ae831c3f8b3"
            },
            addAccountIdQueryParam: true,
            isExternal: true,
            acFeatures: ["Intelligence.dataTransporterAccess"],
            privileges: {
              AND: [],
              OR: []
            },
            language:{
              subCategory:"navbar.subCategory.export",
              appDisplayName:"navbar.appDisplayName.dataTransporter",
            }
          }
        ]
      },
      Manage: {
        icon: "settings",
        languageKey: "navbar.category.manage",
        apps: [
          {
            subCategory: "Account",
            appDisplayName: "Users & Skills",
            moduleName: "LEUserManagement",
            language:{
              subCategory:"navbar.subCategory.account",
              appDisplayName:"navbar.appDisplayName.usersAndSkills",
            }
          },
          {
            subCategory: "Account",
            appDisplayName: "Management Console",
            moduleName: "lp-le-management-console",
            language:{
              subCategory:"navbar.subCategory.account",
              appDisplayName:"navbar.appDisplayName.managementConsole",
            }
          },
          {
            subCategory: "Account",
            appDisplayName: "Integration Hub",
            moduleName: "lp-le-ihub",
            language:{
              subCategory:"navbar.subCategory.account",
              appDisplayName:"navbar.appDisplayName.integrationHub",
            }
          },
          {
            subCategory: "Channels",
            appDisplayName: "Channel Setup",
            moduleName: "lp-le-conversational-connectors-management",
            roles: ["admin", "campaignManager"],
            privileges: {
              AND: [],
              OR: [1730]
            },
            language:{
              subCategory:"navbar.subCategory.channels",
              appDisplayName:"navbar.appDisplayName.channelSetup",
            }
          },
        ]
      }
    }
  }
});
