import LE from 'app';
import $ from 'jquery';
import CONST from 'const';
import AppsLoader from './apps-loader';
import packLoader from 'src/packLoader';
import configureCrossDomain from 'crossDomain';
import LPMtagConfigModel from 'models/LPtagConfigModel';
import { Logger, gChannel, SentryInitializer, SentryAppender } from 'vue-infra';
import NotificationCenter from 'src/components/notification-center/NotificationCenter';
import TopBarNotification from 'src/components/notification-center/appenders/TopBarNotification';
import DesktopNotification from 'src/components/notification-center/appenders/DesktopNotification';
import PersonaNotification from 'src/components/notification-center/appenders/PersonaNotification';
import TabTitleNotification from 'src/components/notification-center/appenders/TabTitleNotification';
import MigrationActivationSource from 'src/components/migration-activation/MigrationActivationSource';
import LaunchpadRouter from 'src/routers/LaunchpadRouter';
import I18n from 'assets/js/i18n/i18n';
import CONSTANTS from '../assets/js/const';
import Settings from 'assets/data/codeTables/settings.json';
import mainVueApps from 'src/components/mainVueApps';
import LEConfig from 'assets/config/le-env-conf';
import { detectZoom } from './detectZoom.js';
import { debounce } from 'lodash';

const logger = Logger.getLogger('CCUI');

// jQuery upgrade - "load" function is renamed to "on".
$.fn.load = $.fn.on;

// jQuery upgrade "size" function is changed to "length" property.
$(function () {
  $.fn.size = function () {
    return this.length;
  };
});

/*
 add initializers that will run when the application is started
 */
LE.on('initialize:before', () => {
});

/*
 add initializers that will run when the application is started
 */
LE.on('initialize', () => {
});

/*
 add callback to run when app is started after all initializers have run
 */
LE.on('start', (options) => {
  //remove the start loader img from dom

  //Navigate to the requested view inside the application / default module
  // TODO: move it and suffer from severe routing issues...
  if (LE.loginInfo.otk || !LE.loginInfo.module && !LE.loginInfo.persona && !LE.loginInfo.prefix) {
    LE.navigateToEntryPointUrl();
  }

  gChannel.trigger('', 'LEStarted');

  setLPtag();
  setMigration();
  setNotificationCenter();
  setSentry();
  setPendo(); //Product analytics
});

// This is the entry point to my app, it will be called after user is authenticated.
const loadAndStart = async () => {
  // Now load the modules

  // This is done here and not inside LEApplication.js since
  // It involves loading all application modules where each module
  // Is requiring the application - to avoid cyclic dependency,
  // This should be located after the application had been loaded

  // this is the point we are invoking configureCrossDomain of our transports, the reason we do it here is to allow separation of session login using fetch
  // while avoiding other transportypes configurations up until the session is authenticated
  configureCrossDomain();

  // Check if Launchpad should be started instead of LiveEngage
  const routingExecuted = await executeLaunchpadRouting();

  if (routingExecuted) {
    return;
  }

  packLoader.load(async () => {
    // Kick-start the application
    try {
      // There are no more truly global components.
      // Each one is registered to a specific app.
      // The main Vue apps need to be initialized before we...
      // use the equivalent of Vue.component
      mainVueApps.createApps();
      await I18n.init();
      await AppsLoader.kickStartApps();
    } catch (e) {
      LE.logger.error(`fail to handle routes, ${JSON.stringify(e)}`);
    }
    LE.start();
  });
};

// Authenticate the user
LE.authenticate({ success: loadAndStart });

const setPendo = () => {
  const {
    visitorId,
    accountId,
    roles,
    is_lpa,
    zone,
    phase,
    datacenter,
    grid,
    theme
  } = getPendoMetadata();
  window.addEventListener('message', setPendoMetadataOnIFrame);
  const isPendoEnabledACFeature = LE.sessionManager.getFeaturePropertyState(CONSTANTS.FEATURES.IS_PENDO_ENABED);

  if(window.pendo && isPendoEnabledACFeature) {
    window.pendo.initialize({
      visitor: {
        id: visitorId,
        roles,
        is_lpa,
        theme
      },
      account: {
        id: accountId,
        zone,
        phase,
        datacenter,
        grid
        // is_paying:    // Recommended if using Pendo Feedback
      },
      preferMutationObserver: true,
    });
  }
};

const getPendoMetadata = () => {
  let metadata = {};
  if(LE && LE.sessionManager) {
    const userId = LE.sessionManager.getConfig() && LE.sessionManager.getConfig().userId || '';
    const phase = LE.getConfig() && LE.getConfig().lp_phase || '';
    const grid = LE.getConfig() && LE.getConfig().lp_grid || '';
    const datacenter = LE.getConfig() && LE.getConfig().lp_dc || '';
    const accountId = LE.sessionManager.getAccountId();
    const accountZone = LE.sessionManager.getAccountZone();
    const roles = LE.sessionManager.getRolesLost();
    const isLPA = LE.sessionManager.isLPA();
    const theme = LE.sessionManager.getAccountSettingValueByID(Settings.SETTING_ID.GENERAL_THEME);
    const visitorId = `${accountId}_${userId}`;
    metadata = {
      userId,
      phase,
      grid,
      datacenter,
      accountId,
      zone: accountZone,
      roles,
      is_lpa: isLPA,
      visitorId,
      theme
    };
  }
  return metadata;
};

const initialZoomLevel = detectZoom();

initialZoomLevel && logger.track('InitialBrowserZoom', {'initialZoomLevel': initialZoomLevel});

window.addEventListener('resize', () => debouncedLogger());

const debouncedLogger = debounce(() => {
  const newZoomLevel = detectZoom();
  newZoomLevel && logger.track('NewBrowserZoom', {'newZoomLevel': newZoomLevel});
}, 2000)

const setSentry = () => {
  if (LEConfig.sentry.isEnabled) {
    const sentry = new SentryInitializer(
      [mainVueApps.navBar, mainVueApps.mountContainer],
      LEConfig.sentry.dsn,
      LEConfig.sentry.sampleRate,
      LEConfig.sentry.tracesSampleRate,
      LEConfig.sentry.tracePropagationTargets,
      LEConfig.sentry.blockedAccounts,
      LEConfig.sentry.profilesSampleRate,
      LEConfig.sentry.userSamplingProperties
    );
    sentry.initialize();
    Logger.addAppender({ name: 'SentryAppender', appender: new SentryAppender() });

    const { enableMemoryTrackingMetrics, enableMemoryTrackingAccountsList } = LEConfig.sentry;
    if (enableMemoryTrackingMetrics) {
      sentry.enableMemoryTracking(enableMemoryTrackingAccountsList);
    }
  }
};
// Adding an event listener so iframes that use Pendo will be able to get relevant metadata
const setPendoMetadataOnIFrame = (event) => {
  const { action, iframeID, iframeURL } = event.data;
  if(action === "getPendoMetadata"){
    if (iframeID) {
      const pendoMetadata = getPendoMetadata();
      const iframe = document.querySelector(iframeID);
      if(iframe) {
        iframe.contentWindow.postMessage({ action: "getPendoMetadata", pendoMetadata }, iframeURL);
      } else {
        LE.logger.error(`setPendoMetadataOnIFrame() -> Failed to get iframe id that was used is: ${iframeID},
         make sure it matches your iframe id`);
      }
    }
  }
};

const setLPtag = () => {

  var customizationObj = LE.sessionManager.getAccountSettingValueByID(LE.sessionManager.ACCOUNT_SETTINGS_ID.LE_UI_CUSTOMIZATION);
  if (customizationObj && (customizationObj.avoidSupportAccountTagging === 'true' || customizationObj.avoidSupportAccountTagging === true)) {
    // This is not a real account, it's a hack for CafeX
    // CafeX accounts should not be tagged with the real support account but the tag is required for engagement window
    LE.LPtag_mtaconfig.siteId = 'dummySupportAccount';
  } else {
    // start LPTag refresh, lets wait until LE App has fully started before fetching first time
    LE.LPMtagModel = new LPMtagConfigModel({});
    setTimeout(() => {
      LE.LPMtagModel.startRefresh();
    }, 30000);
  }

  const onTagAdded = () => {
    lpTagSendUDEs();
    lpTagSetLog();
    LE.vent.trigger('LPtag_mtaconfig:loaded');
  };

  // will trigger a callback after the array is required.
  require(['assets/js/lib/liveperson/LPtag_mtagconfig.js'], onTagAdded);
};

const lpTagSendUDEs = () => {
  LE.lpTagAddVar([
    { name: 'DefaultPersona', val: LE.sessionManager.getDefaultPersona() },
    { name: 'ActivePersona', val: LE.sessionManager.getActivePersona() },
    { name: 'UserId', val: LE.sessionManager.getUserId() },
    { name: 'AccountId', val: LE.sessionManager.getAccountId() },
    { name: 'LoginName', val: LE.sessionManager.getLoginName() }
  ]);
  if (LE.siteSettings) {
    LE.lpTagAddVar([
      { name: 'AccountTimeZone', val: LE.siteSettings.timeZoneSettings.getCurrentTimeZone() }
    ]);
  }
};

const lpTagSetLog = () => {
  window.lpTaglogListeners = window.lpTaglogListeners || [];
  window.lpTaglogListeners.push((msg, type, callingMethod) => {
    if (window.lpTag && (window.lpTag.isLogToLeLogger === undefined || window.lpTag.isLogToLELogger !== false)) {
      const leLogger = Logger.getLogger('lpTag');
      switch (type) {
        case CONST.LP_TAG_LOG_TYPES.DEBUG:
          leLogger.debug(msg, callingMethod);
          break;
        case CONST.LP_TAG_LOG_TYPES.ERROR:
          leLogger.error(msg, callingMethod);
          break;
        case CONST.LP_TAG_LOG_TYPES.INFO:
          leLogger.info(msg, callingMethod);
          break;
        case CONST.LP_TAG_LOG_TYPES.METRICS:
          leLogger.metrics(msg, callingMethod);
          break;
        default:
          leLogger.debug(msg, callingMethod, { 'type': type });
          break;
      }
    }
  });
};

const setMigration = () => {
  // handle creating default campaign/cms/auto messeges
  if (!LE.sessionManager.hasLiveEngage2Feature() && LE.sessionManager.hasMigratingFeature()) {
    const migrationController = new MigrationActivationSource({
      application: LE,
    });
    migrationController.onLogin();
  }
};

const setNotificationCenter = () => {
  LE.notificationCenter = new NotificationCenter();
  if (!LE.sessionManager.isExternal()) {
    LE.notificationCenter.addAppender({ name: 'DesktopNotification', appender: new DesktopNotification() });
    LE.notificationCenter.addAppender({ name: 'TabTitleNotification', appender: new TabTitleNotification() });
    LE.notificationCenter.addAppender({ name: 'PersonaNotification', appender: new PersonaNotification() });
    LE.notificationCenter.addAppender({ name: 'TopBarNotification', appender: new TopBarNotification() });
  }
};

/**
 * Bootstrap execution function to start the Launchpad routing
 * Returns true if routing got executed successfully
 *
 * @returns boolean
 */
const executeLaunchpadRouting = async () => {
  const launchpadRouter = new LaunchpadRouter();
  return launchpadRouter.execute();
};
